export const translateCardRequestType = (origin:string) =>{
    switch (origin) {
      case "NORMAL":
        return "Normal";
      
      case "EXPRESS": 
        return "Exprés";
      
        default:
          return "";
    }
  
  }


  