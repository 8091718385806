import { PlusIcon, UsersIcon } from '@heroicons/react/24/outline';

import GenericTable, {
	DataTableInterface,
} from '../../components/misc/GenericTable';
import useServerUsers from '../../api/userServerUsers';

import Paginate from '../../components/misc/Paginate';
import Modal from '../../components/modals/GenericModal';
import Breadcrumb, {
	type PathInterface,
} from '../../components/navigation/Breadcrumb';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Item } from '../../interfaces/UsersInterfaces';
import NewUserModalVariantOne from './NewUser/NewUserModalVariantOne';
import NewUserModalVariantTwo from './NewUser/NewUserModalVariantTwo';
import EditUserModal from './editUseWizzard/EditUserModal';

const exists = true;

const Users = () => {
	const {
		paginate,
		isLoading,
		allUsers,
		user,
		getAllUsers,
		getUser,
		editUser,
		deleteUser,
		registerUser,
		addFromTecopos,
	} = useServerUsers();

	const [filter, setFilter] = useState<
		Record<string, string | number | boolean | null>
	>({});
	const [addUsermodal, setAddUsermodal] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	// const [exportModal, setExportModal] = useState(false);

	/* useEffect(() => {
			  getAllClients(filter);
			}, [filter]); */

	// Data for table ------------------------------------------------------------------------
	const tableTitles = ['Nombre', 'Correo Electrónico', 'Entidad', 'Roles'];
	const tableData: DataTableInterface[] = [];

	allUsers?.map((item: Item) => {
		if (item.roles && Array.isArray(item.roles)) {
			tableData.push({
				rowId: item.id,
				payload: {
					Nombre: item?.fullName,
					Entidad: item?.issueEntity?.name ?? '-',
					'Correo Electrónico': item.email ?? '-',
					Roles: item.roles[0].name ?? '-',
				},
			});
		}
	});

	const navigate = useNavigate();

	const searching = {
		action: (search: string) => {
			setFilter({ ...filter, search });
		},
		placeholder: 'Buscar usuario',
	};
	const close = () => {
		setEditUserModal({ state: false, id: null });
	};
	const actions = [
		{
			icon: <PlusIcon className='h-5' />,
			title: 'Agregar usuario',
			action: () => {
				setAddUsermodal(true);
			},
		},
	];

	const rowAction = (id: number) => {
		setEditUserModal({ state: true, id });
	};

	// Breadcrumb-----------------------------------------------------------------------------------
	const paths: PathInterface[] = [
		{
			name: 'Usuarios',
		},
	];
	// ------------------------------------------------------------------------------------
	const [nuevoUserModal, setNuevoUserModal] = useState(false);
	const [contactModal, setContactModal] = useState(false);
	const [editUserModal, setEditUserModal] = useState<{
		state: boolean;
		id: number | null;
	}>({ state: false, id: null });

	const closeAddUser = () => {
		setAddUsermodal(false);
	};

	useEffect(() => {
		getAllUsers(filter);
	}, [filter]);

	return (
		<div>
			<Breadcrumb
				icon={<UsersIcon className='h-6 text-gray-500' />}
				paths={paths}
			/>
			<GenericTable
				tableData={tableData}
				tableTitles={tableTitles}
				loading={isLoading}
				searching={searching}
				actions={actions}
				rowAction={rowAction}
				// filterComponent={{ availableFilters, filterAction }}
				paginateComponent={
					<Paginate
						action={(page: number) => {
							setFilter({ ...filter, page });
						}}
						data={paginate}
					/>
				}
			/>

			{editUserModal.state && (
				<Modal state={editUserModal.state} close={setEditUserModal}>
					<p className='mb-4 font-semibold text-lg text-center'>
						Editar usuario
					</p>
					<EditUserModal
						close={close}
						isLoading={isLoading}
						editUser={editUser}
						getUser={getUser}
						id={editUserModal.id}
						user={user}
						allUsers={allUsers}
					/>
				</Modal>
			)}
			{addUsermodal && (
				<Modal state={addUsermodal} close={setAddUsermodal}>
					<div className='flex flex-col gap-4'>
						<p className='mb-4 font-semibold text-lg text-center'>
							Nuevo usuario
						</p>
						<div className=' flex gap-4 items-center'>
							<input
								type='radio'
								value='option1'
								checked={selectedOption === 'option1'}
								onChange={(e) => setSelectedOption(e.target.value)}
							/>
							Nuevo registro de usuario
						</div>
						<div className=' flex gap-4 items-center'>
							<input
								type='radio'
								value='option2'
								checked={selectedOption === 'option2'}
								onChange={(e) => setSelectedOption(e.target.value)}
							/>
							Añadir usuario del ecosistema de Tecopos
						</div>
						{selectedOption === 'option1' && (
							<NewUserModalVariantOne
								close={closeAddUser}
								isLoading={isLoading}
								registerUser={registerUser}
							/>
						)}
					</div>
					{selectedOption === 'option2' && (
						<NewUserModalVariantTwo
							close={closeAddUser}
							isLoading={isLoading}
							addFromTecopos={addFromTecopos}
						/>
					)}
				</Modal>
			)}
		</div>
	);
};

export default Users;
