import { useState } from 'react';
import Input from '../../../components/forms/Input';
import Button from '../../../components/misc/Button';
import { deleteUndefinedAttr, validateEmail } from '../../../utils/helpers';
import Toggle from '../../../components/forms/Toggle';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
	AccountData,
	UserInterface,
} from '../../../interfaces/ServerInterfaces';
import { TrashIcon } from '@heroicons/react/24/outline';
import Modal from '../../../components/modals/GenericModal';
import AlertContainer from '../../../components/misc/AlertContainer';
import {
	type BasicType,
	type SelectInterface,
} from '../../../interfaces/InterfacesLocal';
import TextArea from '../../../components/forms/TextArea';
import Select from '../../../components/forms/Select';
import ComboBox from '../../../components/forms/Combobox';
import AsyncComboBox from '../../../components/forms/AsyncCombobox';
import useServerAccounts from '../../../api/userServerAccounts';
import { useNavigate } from 'react-router-dom';
import AsyncMultiSelect from '../../../components/forms/AsyncMultiselect';

interface EditInterface {
	account: any;
	editAccount: Function;
	deleteAccount: Function;
	closeModal: Function;
	isFetching: boolean;
	id: number | null;
	allAccounts: any;
	getAccount: Function;
}

const DetailAccountEditComponent = ({
	editAccount,
	deleteAccount,
	account,
	closeModal,
	isFetching,
	id,
	allAccounts,
	getAccount,
}: EditInterface) => {
	const { control, handleSubmit, watch, reset, formState } = useForm<BasicType>(
		{
			mode: 'onChange',
		},
	);
	const desiredCurrencyCodeEntityObject: any = allAccounts.find(
		(item: any) => item.id === id,
	);
	const navigate = useNavigate();

	const [delAction, setDelAction] = useState(false);

	const onSubmit: SubmitHandler<BasicType> = (data) => {
		editAccount(account?.id, deleteUndefinedAttr(data))
			.then(() => closeModal())
			.then(() => getAccount(id));
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='h-96 overflow-auto scrollbar-thin scrollbar-thumb-slate-100 pr-5 pl-2'>
					<div className='flex justify-end'>
						<div className='bg-red-200 hover:bg-red-300 transition-all duration-200 ease-in-out  rounded-lg'>
							<Button
								icon={<TrashIcon className='h-5 text-red-500' />}
								color='gray-50'
								type='button'
								action={() => {
									setDelAction(true);
								}}
								outline
							/>
						</div>
					</div>
					<div>
						<Input
							name='name'
							defaultValue={account?.name}
							label='Nombre'
							control={control}
							rules={{
								required: 'Campo requerido',
							}}
						/>

						<AsyncMultiSelect
							name='allowedUsersId'
							normalizeData={{ id: 'id', name: 'fullName' }}
							defaultItem={{
								id: account?.id,
								name: account?.allowedUsers,
							}}
							control={control}
							label='Usuarios permitidos'
							dataQuery={{ url: '/user' }}
						/>

						<div className='flex justify-around gap-5'>
							<Toggle
								name='isPrivate'
								defaultValue={account?.isPrivate}
								title='Cuenta privada'
								control={control}
							></Toggle>

							<Toggle
								name='isBlocked'
								defaultValue={account?.isBlocked}
								title='Cuenta bloqueada'
								control={control}
							></Toggle>
							<Toggle
								name='isActive'
								title='Cuenta activa'
								defaultValue={account?.isActive}
								control={control}
							></Toggle>
						</div>
						<TextArea
							defaultValue={account?.description}
							name='description'
							control={control}
							label='Descripción'
						></TextArea>

						<div className='flex justify-end mt-5'>
							<Button
								name='Insertar'
								color='slate-600'
								type='submit'
								loading={isFetching}
								disabled={isFetching}
							/>
						</div>
					</div>
				</div>
			</form>

			{delAction && (
				<Modal state={delAction} close={setDelAction}>
					<AlertContainer
						onAction={() => deleteAccount(id, navigate('/accounts'))}
						onCancel={setDelAction}
						title={`Eliminar ${account?.name}`}
						text='¿Seguro que desea eliminar este usuario del sistema?'
						loading={isFetching}
					/>
				</Modal>
			)}
		</>
	);
};

export default DetailAccountEditComponent;
